import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import { logout } from "../../../redux/actions/authAction";
import SidebarLink from "./SidebarLink";
import { routes } from "../../App/Router";
import { Firebase } from "../../firebase";

export default function OrganisationSidebar() {
  const [t] = useTranslation("common");
  const authDispatch = useDispatch();
  const history = useHistory();

  const firebase = React.useMemo(() => new Firebase(), []);
  const projectId = process.env.REACT_APP_FIREBASE_PROJECT_ID;

	const handleLogout = async (evt) => {
		console.log('log out', evt);
		evt?.preventDefault();
		try {
			await firebase._signOut();
			authDispatch(logout());
			history.push(routes.organisation.login);
		} catch (error) {
			toast.error(t('global.something_went_wrong'));
		}
	};
	return (
		<ul className="sidebar__block">
			<SidebarLink
				title={t('dashboard_fitness.page_title')}
				icon="home"
				route={routes.organisation.dashboard}
			/>
			<SidebarLink
				title={t('account.title')}
				icon="user"
				route={routes.organisation.profile}
			/>
			<SidebarLink
				title={t('challenge.page_title')}
				icon="target"
				route={routes.organisation.challengeDashboard}
			/>
			{
				projectId == "greenplaysherbrooke" ?
				<div>
				</div>
			 : <div>
			 <SidebarLink
					title={t('branch.page_title')}
					icon="file-add"
					route={routes.organisation.branch}
				/>
				</div>
			}

			<SidebarLink
				title={t('FAQ')}
				icon="sidebar__link-icon lnr lnr-bubble"
				route={routes.organisation.FAQ}
			/>
			{/* <SidebarLink
				title={t('account.profile.settings')}
				icon="sidebar__link-icon lnr lnr-bubble"
				route={routes.organisation.settings}
			/> */}
			<SidebarLink
				title={t('log_out')}
				icon="exit"
				onClick={handleLogout}
				route={routes.organisation.login}
			/>
		</ul>
	);
}
