import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../../shared/providers/AuthProvider";

import { logout } from "../../../redux/actions/authAction";
import SidebarLink from "./SidebarLink";
import { routes } from "../../App/Router";
import { Firebase } from "../../firebase";

import cityHooks from "hooks/city.hooks";
import usersHooks from "hooks/users.hooks";


export default function CitySidebar() {
  const [t] = useTranslation("common");
  const authDispatch = useDispatch();
  const history = useHistory();
  const {userId, adminData} = usersHooks.useExternalUser();
  const { limitSettings } = cityHooks.useFetchCityLimitSettings(userId);


  const firebase = React.useMemo(() => new Firebase(), []);

  const handleLogout = async (evt) => {
    console.log("log out", evt);
    evt?.preventDefault();
    try {
      await firebase._signOut();
      authDispatch(logout());
      history.push(routes.city.login);
    } catch (error) {
      toast.error(t("global.something_went_wrong"));
    }
  };
  return (
    <ul className="sidebar__block">
      <SidebarLink
        title={t("dashboard_fitness.page_title")}
        icon="home"
        route={routes.city.dashboard}
      />
      <SidebarLink
        title={t("account.title")}
        icon="user"
        route={routes.city.profile}
      />
      {limitSettings?.c19_carpooling_app?.greenplay_addon &&
        <SidebarLink
          title={t("challenge.page_title")}
          icon="target"
          route={routes.city.challengeDashboard}
        />}
      {/* <SidebarLink
        title={t("branch.page_title")}
        icon="file-add"
        route={routes.city.branch}
      /> */}
      {limitSettings?.c19_carpooling_app?.greenplay_addon &&
        <SidebarLink
          title={t("notification.page_title")}
          icon="file-add"
          route={routes.city.notification}
        />}
      {limitSettings?.c19_carpooling_app?.greenplay_addon &&
        <SidebarLink
          title={t("emails.page_title")}
          icon="file-add"
          route={routes.city.email}
        />}
      {limitSettings?.c19_carpooling_app?.greenplay_addon &&
        <SidebarLink
          title={t('dashboard_commerce.badges')}
          icon="target"
          route={routes.city.badge}
        />}
      {limitSettings?.c19_carpooling_app?.greenplay_addon &&
        <SidebarLink
          title={t('dashboard_commerce.news')}
          icon="target"
          route={routes.city.news}
        />}
      {limitSettings?.c19_carpooling_app?.greenplay_addon &&
        <SidebarLink
          title={t('happy_hours.page_title')}
          icon="target"
          route={routes.city.happyHours}
        />}
      {limitSettings?.c19_carpooling_app?.greenplay_addon &&
        <SidebarLink
          title={t('dashboard_commerce.stores')}
          icon="target"
          route={adminData?.tenantId ? `${routes.tenant.dashboard}?tenantId=${adminData?.tenantId}` : routes.tenant.dashboard}
        />}
      {limitSettings?.c24_mailerlite_integration?.granted &&
        <SidebarLink
          title={t('dashboard_commerce.mailerlite')}
          icon="target"
          route={routes.city.mailerLite}
        />}
      <SidebarLink
        title={t('account.profile.settings')}
        icon="sidebar__link-icon lnr lnr-bubble"
        route={routes.city.settings}
      />
      {/* <SidebarLink
				title={t('meta.city.maps')}
				icon="sidebar__link-icon lnr lnr-bubble"
				route={routes.city.maps}
			/> */}
      <SidebarLink
        title={t("meta.city.KBS")}
        icon="sidebar__link-icon lnr lnr-bubble"
        route={routes.city.KBS}
      />
      <SidebarLink
        title={t("log_out")}
        icon="exit"
        onClick={handleLogout}
        route={routes.city.login}
      />
    </ul>
  );
}
